<template>
<div class="authentication-section row">
    <div class="authentication-container row">
        <img class="star-image" :src="star"/>
        <img class="map-image" :src="mapImage"/>
        <div class="left-section">
            <p>{{$t("welcomeMessage")}}</p>
            <p>{{$t("texasHistoryName")}}</p>
            <p>{{$t("awakens")}}</p>
        </div>
        <div class="right-section"
            :class="[{'storing-signup-container': currentSection == 'sign-up'}]">
            <component :is="currentSection"/>
        </div>
    </div>
</div>
</template>

<script>
import SignIn from "@/components/Authentication/SignIn.vue";
import ForgotPassword from "@/components/Authentication/ForgotPassword.vue";
import UpdatePassword from "@/components/Authentication/UpdatePassword.vue";

export default{
    name: "Authentication",
    components: {
        "sign-in": SignIn,
        "forgot-password": ForgotPassword,
        "update-password": UpdatePassword,
    },
    computed: {
        currentSection(){
            return this.$store.state.authenticationForm
        }, 
        star(){
             return require('@/assets/icons/ic_star.svg')
        },
        mapImage(){
            return require('@/assets/Texas Map asset.png')
        }
    },
    created(){
        if(this.$route.meta.form){
            this.$store.commit('updateAuthenticationForm', this.$route.meta.form)
        }
    }
}
</script>

<style lang="less" scoped>
.authentication-section{
    width: 100%;
    height: calc(100vh - 80px);
    background-color: #0A1554;
    margin-top: 80px;
    .authentication-container{
        width: 1024px;
        min-width: 1024px;
        height: 100%;
        margin: auto;

        .star-image{
            position: absolute;
            margin-top: 336px;
            margin-left: 349px;
            z-index: 1;
            height: 50px;
            width: 50px;

            @media screen and (max-width:1141px) {
                margin-left: 425px;
            }
        }

        .map-image{
            position: absolute;
            margin-top: 84px;
            margin-left: -100px;
            z-index: 1;
            height: 262px;
            width: 250px;

            @media screen and (max-width:1141px) {
                margin-left: -20px;
            }
        }

        .left-section{
            z-index: 2;
            width: 350px;
            min-width: 350px;
            margin-top: 142px;
            margin-left: 124px;
            @media screen and (max-width:1141px) {
                margin-left: 200px;
                width: 280px;
                min-width: 200px;
            }

            :first-child{
                color: #FFFFFF;
                font-size: 45px;
                line-height: 64px;
            }
            :nth-child(2){
                color: #FFFFFF;
                font-weight: 600;
                font-size: 45px;
                margin-bottom: 0;
                margin-left: -30px;
            }
            :last-child{
                color: #ff4c4c;
                font-size: 45px;
                margin-top: -10px;
                letter-spacing: 2px;
                font-weight: 600;
                margin-left: -30px;
            }
        }
        .right-section{
            margin-left: 100px;
            width: 416px;
            border-radius: 8px;
            background-color: #FFFFFF;
            height: 398px;
            margin-top: 151px;
            margin-right: 16px;
        }

        .storing-signup-container{
            height: 456px;
            margin-top: 120px;
        }
    }
}
</style>
