<template>
    <div class="forgot-password-container column">
        <div>
            <p>{{this.$t("resetPasswordHeaderText")}}</p>
        </div>
        <div class="credentials column">
            <label for="password" :class="[{visibility: password == ''}]">
                {{$t("signPasswordLabelText")}}</label>
            <input type="password" name="password" v-model="password" 
                    :class="{'invalid-field': !isPasswordValid}"
                    :placeholder='$t("signPasswordLabelText")'/>
            <label for="passwordConfirmation" :class="[{visibility: password == ''}]">
                {{$t("passwordConfirmationLabelText")}}</label>
            <input type="password" name="passwordConfirmation" v-model="passwordConfirmation" 
                    :class="{'invalid-field': !passwordConfirmationMatches}"
                    :placeholder='$t("passwordConfirmationLabelText")'/>
        </div>
        <div class="sign-buttons row">
            <button class="btn round primary" :class="[{'disabled': !passwordConfirmationMatches || !isPasswordValid}]"
                    @click="updatePassword()">{{$t('next')}}</button>
            <button class="btn round secondary" @click="signIn()">
               {{$t('back')}} </button>
        </div>
    </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService.js"
// import ValidationHelper from '@/utils/ValidationHelper.js'
export default{
    name: "UpdatePassword",
    data: function(){
        return { 
            password: "",
            passwordConfirmation: "",
        };
    },
    computed: {
        isPasswordValid(){
            return true // ValidationHelper.validatePassword(this.password)
        },
        passwordConfirmationMatches(){
            return this.password === this.passwordConfirmation
        }
    },
    methods: {
        updatePassword(){
            let passwordUpdated = {
                confirmationCode: this.$route.query.ConfirmationCode,
                password: this.password
            }
            AuthenticationService.updatePassword(passwordUpdated)
                .then(()=> {
                    this.$store.commit('updateAuthenticationForm', 'sign-in')
                    this.$router.push({name:'SignIn'})
                })
        }, 
        signIn(){
            this.$store.commit('updateAuthenticationForm', 'sign-in')
            this.$router.push({name:'SignIn'})
        }
    }
    
}
</script>
<style lang="less" scoped>
.forgot-password-container{
    width: 329px;
    height: 302px;
    padding: 46px 43px 50px 44px;

    p{
        margin: 0;

        width: 261px;
        color: rgba(0,0,0,0.77);
        font-family: Montserrat;
        font-size: 24px;
        line-height: 32px;
    }

    .credentials{
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;

        label{
            height: 14px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 6px;
        }

        label.visibility{
            visibility: hidden;
        }

        input{
            width: 100%;
            border: none;
            border-bottom: 1px solid #9E9E9E;

            height: 24px;
            min-height: 24px;
            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;

            margin: 0;
            padding-bottom: 3px;

            &:focus{
                outline: none;
            }
        }

        input:first-of-type {
            margin-bottom: 23px;
        }
    }

    .sign-buttons{
        width: 100%;
        margin-bottom: 0;


        button:first-child{
            height: 40px; 
        }

        button:nth-child(2){
            margin-left: 23px;
            height: 42px;
        }
        button.disabled{
            background-color: rgba(158, 158, 158, 0.801);
        }
    }
}
</style>
